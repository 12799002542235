import React from 'react';
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import catalogueCover from '../../../assets/images/albums/installations/curatorial-project/state-of-the-earth-catalogue-cover.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <p>
        "State of the Earth" - 21 Women Artists on Global Biodiversity Crisis
      </p>
      <p>
        Liliana Folta<br />
        <span style={{ fontStyle: 'italic' }}>
          Interdisciplinary Artist and Independent Curator
        </span><br />
        <span style={{ fontStyle: 'italic' }}>
          Advocate of native plants
        </span>
      </p>
      <p>
        This catalog compiles the works of 21 women artists from different parts of the world, whose new proposals represent a concern for the biodiversity of their homeland or the places they chose to live.
      </p>
      <p>
        A year and a half in the making, the project was presented at the Midway Gallery in Boston, Massachussetts, September 2023.
      </p>
      <p>
        This project is partnering with the Pontificia Universidad Católica Argentina - Buenos Aires, for the extended exhibition in their virtual gallery.<br /><br />
        Co-curated with Verónica Parselis, Professor of Philosophy and Aesthetics.
      </p>
      <a 
        href="https://biblioteca.uca.edu.ar/expo/visiones/visiones.html" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        https://biblioteca.uca.edu.ar/expo/visiones/visiones.html
      </a><br /><br />
      <a
        href="https://midwayartiststudios.org/exhibitions/state-of-emergency-state-of-the-earth/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://midwayartiststudios.org/exhibitions/state-of-emergency-state-of-the-earth/
      </a><br /><br />
      <p>Catalog:&nbsp;</p>
      <a
        href="https://heyzine.com/flip-book/1aa0b6c23c.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={catalogueCover} alt="Interactive flipbook for State of the Earth catalogue" />
      </a>
    </main>
    <Footer />
  </Layout>
)
